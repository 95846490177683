export interface IRoute {
  route: string
  name: string
}

export const mainRoutes: IRoute[] = [
  { route: "/", name: "home" },
  { route: "/hire", name: "hire" },
  { route: "/blog", name: "blog" },
  { route: "/about", name: "about" },
]

export const extendedRoutes: IRoute[] = [
  ...mainRoutes,
  { route: "/imprint", name: "imprint" },
  { route: "/privacy-policy", name: "privacy" },
]

export const twitterProfile = "https://twitter.com/derBoii"
export const koFiProfile = "https://ko-fi.com/hendrikgruber"
export const linkedInProfile = "https://www.linkedin.com/in/hendrik-gruber/"
export const contactEmail = "gruber.hendrik@gmail.com"

import React, { FunctionComponent, useState } from "react"
import { Box, Flex, Image, Spacer } from "@chakra-ui/react"
// @ts-ignore
import logo from "../assets/images/logo_main.png"
import { mainRoutes } from "../utils/routes"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { colors, maxContentWidth, switchMobile } from "../theme/theme"
import LoadingSkeleton from "./LoadingSkeleton"

const StyledLink = styled(Link)`
  color: ${colors.grey.mediumDark};
  :hover {
    color: ${colors.grey.medium};
  }
`

const StyledContent = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: ${maxContentWidth};
`

const StyledHeader = styled(Flex)`
  padding: 0.5rem 3rem;
  display: flex;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 4px 6px 0 rgb(12 0 46 / 6%);
  width: 100%;
  @media (max-width: ${switchMobile}) {
    padding: 0.5rem 0.5rem;
  }
`

const Header: FunctionComponent = () => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  return (
    <StyledHeader>
      <StyledContent>
        <Box w="100px">
          <Link to={mainRoutes[0].route}>
            <Image
              src={logo}
              height="3.5rem"
              margin="0"
              title="Hendrik Gruber Logo"
              style={{ display: `${imageLoaded ? "" : "none"}` }}
              onLoad={() => {
                setImageLoaded(true)
              }}
            />
            {!imageLoaded && (
              <LoadingSkeleton
                blueLoader={true}
                height="3.5rem"
                width="3.5rem"
              />
            )}
          </Link>
        </Box>
        <Spacer />
        <Box>
          <Flex>
            {mainRoutes.map((elem, index) => {
              return (
                <Box padding="0 0.25rem" key={index}>
                  <nav>
                    <StyledLink to={elem.route}>{elem.name}</StyledLink>
                  </nav>
                </Box>
              )
            })}
          </Flex>
        </Box>
      </StyledContent>
    </StyledHeader>
  )
}

export default Header

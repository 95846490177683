export const fonts = {
  fonts: {
    heading: "Roboto",
    body: "Roboto",
  },
}

export const colors = {
  blue: {
    blue0: "#03045E",
    blue1: "#023E8A",
    blue2: "#0077B6",
    blue3: "#0096C7",
    blue4: "#00B4D8",
    blue5: "#48CAE4",
    blue6: "#90E0EF",
    blue7: "#ADE8F4",
    blue8: "#CAF0F8",
  },
  white: "#fff",
  black: "#000",
  grey: {
    light: "#F5F5F5",
    medium: "#B0B0B0",
    mediumDark: "#696969",
    dark: "#4A4A4A",
  },
  red: "#c70032",
  green: "#00c41a",
}

export const shadows = {
  textShadow: "",
  containerShadow: "4px 4px 8px rgba(0, 0, 0, .3)",
  containerShadowHighlight: "4px 4px 8px rgba(2, 62, 138, .3)",
  buttonShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
}

export const cardRadius = "15px"

export const maxContentWidth = "64rem"
export const switchMobile = "750px"
export const footerWidth = "580px"
export const switchBlogPageHeaderMobileNumber = 750
export const switchBlogPageHeaderMobile =
  switchBlogPageHeaderMobileNumber.toString() + "px"

export const hoverScaleEffect = `

  transition: transform 0.2s;

  :hover {
    transform: scale(1.03);
  }
`

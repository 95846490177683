import React, { FunctionComponent, useEffect, useMemo, useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  Spacer,
  Button,
} from "@chakra-ui/react"
import Cookies from "universal-cookie"
import CustomButton from "../components/CustomButton"
import { colors } from "../theme/theme"
import { Link } from "gatsby"

const cookies = new Cookies()

const gaId = "G-PSK69LF61L"
const disableString = "ga-disable-" + gaId

export const disableGoogleAnalytics = (disable: boolean) => {
  cookies.set(disableString, disable, { path: "/" })
  return true
}

export const CookieBanner: FunctionComponent = ({}) => {
  const [showCookies, setShowCookies] = useState<boolean>(false)

  const isAnalyticsAllowed = useMemo(() => {
    const cookieSetting = cookies.get(disableString)
    return cookieSetting
  }, [])

  useEffect(() => {
    if (isAnalyticsAllowed == null) {
      setShowCookies(true)
    } else if (isAnalyticsAllowed === false) {
      setShowCookies(false)
    } else {
      setShowCookies(false)
    }
  }, [])

  const disableCookies = (isDisabled: boolean) => {
    disableGoogleAnalytics(isDisabled)
    setShowCookies(false)
  }

  return (
    <Modal isOpen={showCookies} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cookie Policy</ModalHeader>
        <ModalBody paddingBottom="1.5rem">
          I'd like to save some cookies for analytics in order to track how many
          views pages get which helps me to improve my blog. You can read more
          about it{" "}
          <Link to="/privacy-policy" style={{ color: colors.blue.blue2 }}>
            here
          </Link>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Flex width="100%" alignItems="center" margin="0 auto">
            <Button
              variant="ghost"
              color={colors.grey.medium}
              onClick={() => disableCookies(true)}
            >
              Disable
            </Button>
            <Spacer />
            <CustomButton
              text="Accept"
              clickedHandler={() => disableCookies(false)}
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import React, { FunctionComponent } from "react"
import { Box, Flex, Spacer } from "@chakra-ui/react"
import {
  colors,
  footerWidth,
  maxContentWidth,
  switchMobile,
} from "../theme/theme"
import styled from "@emotion/styled"
import { currentYear } from "../utils/globalVariables"
import { extendedRoutes } from "../utils/routes"
import { Link } from "gatsby"

const StyledFlex = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: ${footerWidth}) {
    flex-wrap: wrap;
    align-items: flex-end;
    align-content: flex-end;
  }
`

const StyledContent = styled(Flex)`
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: ${maxContentWidth};
`

const StyledFooter = styled(Flex)`
  padding: 0.5rem 3rem;
  width: 100%;
  background-color: ${colors.grey.dark};
  color: ${colors.grey.light};
  min-height: 4.5rem;
  @media (max-width: ${switchMobile}) {
    padding: 0rem 0.5rem;
  }
`

const StyledLink = styled(Link)`
  color: ${colors.grey.medium};
  :hover {
    color: ${colors.grey.light};
  }
`

const Footer: FunctionComponent = () => {
  return (
    <StyledFooter>
      <StyledContent>
        <Box>&#169; {currentYear} HendrikGruber.com </Box>
        <Spacer padding="0 0.4rem" />
        <StyledFlex>
          {extendedRoutes.map((elem, index) => {
            return (
              <Box padding="0 0.25rem" key={index}>
                <nav>
                  <StyledLink to={elem.route}>{elem.name}</StyledLink>
                </nav>
              </Box>
            )
          })}
        </StyledFlex>
      </StyledContent>
    </StyledFooter>
  )
}

export default Footer

import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { getTagsArray } from "../utils/getTagsArray"
import logo from "../assets/images/logo_main.png"
import { colors } from "../theme/theme"

interface ISEOProps {
  description?: string
  lang?: string
  meta?: Array<{ name: string; content: string }>
  title: string
  secondaryTitle?: string
  type?: string
  url?: string
  published?: string
  tags?: string
  previewImage?: string | undefined
}

export const Seo: FunctionComponent<ISEOProps> = ({
  description = "",
  lang = "en",
  meta = [],
  title,
  secondaryTitle,
  type = "website",
  url,
  published,
  tags,
  previewImage = logo,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription: string = description || site.siteMetadata.description

  const getTags = () => {
    if (!tags) return
    const tagsArray = getTagsArray(tags)
    return tagsArray.map(elem => {
      return {
        property: "article:tag",
        content: elem,
      }
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: "theme-color",
          content: colors.blue.blue2,
        },
        {
          name: `description`,
          content: description ? description : metaDescription,
        },
        {
          property: `og:title`,
          content: secondaryTitle ? title + " - " + secondaryTitle : title,
        },
        {
          property: `og:description`,
          content: description ? description : metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:url`,
          content: url
            ? "http://hendrikgruber.com" + url
            : "http://hendrikgruber.com",
        },
        {
          property: `og:site_name`,
          content: "HendrikGruber.com",
        },
        {
          property: `keywords`,
          content: tags,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: secondaryTitle ? title + " - " + secondaryTitle : title,
        },
        {
          name: `twitter:description`,
          content: description ? description : metaDescription,
        },
        {
          name: `twitter:image`,
          content: "https://www.hendrikgruber.com" + previewImage,
        },
        {
          name: `article:published_time`,
          content: published,
        },
      ]
        .concat(meta)
        .concat(getTags() ?? [])}
    />
  )
}

export default Seo

import React, { FunctionComponent } from "react"
import { Skeleton } from "@chakra-ui/react"
import { cardRadius, colors } from "../theme/theme"

interface ILoadingSkeletonProps {
  radius?: "cardRadius" | "100%"
  height: string
  width: string
  styles?: React.CSSProperties
  blueLoader?: boolean
}

export const LoadingSkeleton: FunctionComponent<ILoadingSkeletonProps> = ({
  radius = "0",
  height,
  width,
  styles,
  blueLoader = false,
}) => {
  return (
    <Skeleton
      borderRadius={radius === "cardRadius" ? cardRadius : radius}
      startColor={blueLoader ? colors.blue.blue2 : colors.grey.medium}
      endColor={blueLoader ? colors.blue.blue5 : colors.grey.light}
      height={height}
      width={width}
      style={styles}
    />
  )
}
export default LoadingSkeleton

import "@fontsource/roboto/400.css"

import React, { FunctionComponent } from "react"
import { ChakraProvider, Flex } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { extendTheme } from "@chakra-ui/react"
import { colors, fonts, maxContentWidth, switchMobile } from "../theme/theme"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import { CookieBanner } from "../utils/disableGoogleAnalytics"

const LayoutWrapper = styled.div`
  min-height: calc(100vh - 39rem);
  padding: 0.5rem 3rem;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  max-width: ${maxContentWidth};
  @media (max-width: ${switchMobile}) {
    padding: 0rem 0.5rem;
  }
`

export const CustomWrapper = styled(Flex)`
  align-items: center;
  margin: 1.5rem auto;
  width: 100%;
`

interface ILayoutProps {
  disableCookiePopup?: boolean
  styles?: React.CSSProperties
  preLayout?: React.ReactNode
  children: React.ReactNode
}

export const Layout: FunctionComponent<ILayoutProps> = ({
  children,
  disableCookiePopup = false,
  styles,
  preLayout,
}) => {
  const theme = extendTheme({ colors, fonts })

  return (
    <ChakraProvider theme={theme}>
      <Header />
      {preLayout}
      <LayoutWrapper>
        {!disableCookiePopup && <CookieBanner />}
        <CustomWrapper style={styles}>{children}</CustomWrapper>
      </LayoutWrapper>
      <Footer />
    </ChakraProvider>
  )
}

export default Layout

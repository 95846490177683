import React, { FunctionComponent } from "react"
import { Button } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { colors, hoverScaleEffect, shadows } from "../theme/theme"

interface IGradientColors {
  mainLeft: string
  mainRight: string
  hoverLeft: string
  hoverRight: string
}
interface ICustomButtonProps {
  animated?: boolean
  text: string
  type?: "button" | "submit" | "reset"
  styles?: React.CSSProperties
  disabled?: boolean
  clickedHandler?: () => void
  gradientColors?: IGradientColors
}

export const CustomButton: FunctionComponent<ICustomButtonProps> = ({
  animated = true,
  text,
  type = "button",
  disabled,
  styles,
  clickedHandler,
  gradientColors,
}) => {
  const StyledButton = styled(Button)`
    background-image: linear-gradient(
      to right,
      ${gradientColors ? gradientColors.mainLeft : colors.blue.blue2},
      ${gradientColors ? gradientColors.mainRight : colors.blue.blue5}
    );
    color: ${gradientColors ? colors.black : colors.white};
    padding: 1rem 1.7rem;
    box-shadow: ${shadows.buttonShadow};

    :hover {
      background-image: linear-gradient(
        to right,
        ${gradientColors ? gradientColors.hoverLeft : colors.blue.blue5},
        ${gradientColors ? gradientColors.hoverRight : colors.blue.blue8}
      );
      color: ${gradientColors ? colors.black : colors.white};
    }
  `

  const AnimatedStyledButton = styled(StyledButton)`
    ${hoverScaleEffect}
  `

  if (animated) {
    return (
      <AnimatedStyledButton
        isDisabled={disabled}
        type={type}
        aria-label={text}
        style={styles}
        onClick={clickedHandler}
      >
        {text}
      </AnimatedStyledButton>
    )
  } else {
    return (
      <StyledButton
        isDisabled={disabled}
        type={type}
        aria-label={text}
        style={styles}
        onClick={clickedHandler}
      >
        {text}
      </StyledButton>
    )
  }
}

export default CustomButton
